<template>
  <div @click="goToDetail(blog.entry.slug)" class="d-flex mt-3">
    <div class="me-3">
      <img :src="blog.image" class="rounded-s" width="100" height="80" />
    </div>
    <div class="">
      <h5 class="font-600">
        {{ blog.entry.title }}
      </h5>
      <p class="mb-0 mt-n2"><timeago :datetime="blog.publication_date"
      /></p>
    </div>
    <div></div>
  </div>
  
</template>

<script>
import axios from "axios";
export default {
  name: "BlogItem",
  props: ["blog"],
  data() {
    return {};
  },

  mounted() {},

  methods: {
    goToDetail(slug) {
      this.$router.push({
        name: "ArticleDetail",
        params: { slug: slug },
      });
    },
    imageUrlAlt(event) {
      event.target.src = "/images/defaults/default_logo.png";
    },
  },
};
</script>
