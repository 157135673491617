<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
  <div id="page">
    <Header :add_class="['header-auto-show']" />
    <Footer logged />
    <!-- Page Title-->
    <div class="page-title page-title-fixed">
      <a
        @click="$router.go(-1)"
        class="page-title-icon rounded-s ms-3 bg-theme color-theme"
        ><i class="fas fa-chevron-left"></i
      ></a>
      <h1 class="mx-auto font-18">Artikel</h1>
      <a data-menu="menu-main" class="page-title-icon rounded-s ms-3"></a>
    </div>
    <div class="page-title-clear"></div>

    <!-- End Page Title-->

    <div class="page-content pb-3">
      <div class="">
        <div class="text-center">
          <div class="content">
            <div
              class="search-box search-dark shadow-xl border-0 bg-theme rounded-sm bottom-0"
            >
              <i class="fa fa-search"></i>
              <input
                type="text"
                class="border-0"
                placeholder="Cari Artikel"
                v-model="dataSearch"
                @keyup.enter="searchFilter"
              />
              <i
                v-if="dataSearch"
                @click="resetSearch"
                style="
                  position: absolute;
                  right: 0px;
                  line-height: 52px;
                  padding: 0px 40px 0px 50px;
                "
                class="fa fa-times"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-style pb-3">
        <div class="content mb-0 mt-0">
          <div class="">
            <BlogItem
              v-for="blog in listBlog"
              :key="blog.entry.id"
              :blog="blog"
            />
            <div v-if="listBlog.length === 0" class="mb-5">
              <EmptyState
                :message1="'Maaf saat ini belum ada artikel yang tersedia'"
                :cardCenter="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div data-menu-load="menu-footer.html">
        <div class="card card-style"></div>
      </div>
    </div>
    <!-- Page content ends here-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="250"
    >
      <MenuShare />
    </div>

    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import axios from "axios";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import BlogItem from "@/components/blog/BlogItem";
import EmptyState from "@/components/EmptyState";
import MenuShare from "@/components/menu/MenuShare.vue";
export default {
  name: "BlogList",
  components: {
    Header,
    Footer,
    MenuShare,
    BlogItem,
    EmptyState
  },
  data() {
    return {
      apiEndpoint: process.env.VUE_APP_ROOT_API,
      dataSearch: "",
      listBlog: [],
      listBlogId: [],
      isLoading: true,
      next: "",
      errors: [],
    };
  },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
    this.getBlog();
    this.getMoreBlog();
  },
  methods: {
    menuOpen,
    menuClose,
    resetSearch() {
      this.getBlog();
      this.isLoading = true;
      this.listBlog = [];
      this.listBlogId = [];
      this.dataSearch = "";
    },
    searchFilter() {
      this.isLoading = true;
      this.listBlog = [];
      this.listBlogId = [];
      this.getBlog(true);
    },
    getMoreBlog() {
      window.onscroll = () => {
        var bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight;
        if (bottomOfWindow && this.next !== null) {
          this.isLoading = true;
          this.getBlog();
        }
      };
    },
    getBlog(search = false) {
      let url = "/api/v1/blog/";
      search ? (url += "?search=" + this.dataSearch) : "";
      if (this.next && !search) {
        let urlNext = this.next;
        const splitUrl = urlNext.split("://");
        let next;
        if (!this.apiEndpoint.includes("localhost")) {
          next = "https://" + splitUrl[1];
        } else {
          next = this.next;
        }
        url = next;
      }
      axios
        .get(url)
        .then((response) => {
          this.next = response.data.next;
          const data = response.data;
          data.results.forEach((item) => {
            if (item.entry) {
              if (!this.listBlogId.includes(item.entry.id)) {
                this.listBlog.push(item);
                this.listBlogId.push(item.entry.id);
              }
            }
          });
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors.push("Something went wrong please try again");
            console.log(JSON.stringify(error));
          }
        });
    },
  },
};
</script>
